<template>
  <div class="card-container" v-if="getRosetteVisibility">
    <VueText :isSingleLine="true" sizeLevel="6" weightLevel="3" color="white-100">{{
      AktifPlusTrackingBarTitle
    }}</VueText>
    <div
      :class="[
        pinpointVisible ? 'progress-container progress-container-pinpoint' : 'progress-container',
      ]"
    >
      <div v-for="(rossette, index) in rossettes" :key="index" :class="rossette.class">
        <img :src="rossette.img" />
        <img
          v-if="rossette.class === 'empty' && checkEarlierPinpoint(index)"
          id="pinpoint"
          src="@/assets/images/rossette-pinpoint.svg"
        />
      </div>
    </div>
    <div class="info-text">
      <VueIcon
        :width="constants.icons.info.width"
        :height="constants.icons.info.height"
        :iconName="constants.icons.info.name"
        :iconColor="constants.icons.info.color"
      ></VueIcon>
      <VueText sizeLevel="3" weightLevel="2" color="white-100">{{
        this.AktifPlusTrackingBarContent
      }}</VueText>
    </div>
    <div class="prev-month-reward" v-if="isVisibleSurpriseAwardBox">
      <VueIcon
        :width="constants.icons.iconAktifPlusKey.width"
        :height="constants.icons.iconAktifPlusKey.height"
        :iconName="constants.icons.iconAktifPlusKey.name"
        :iconColor="constants.icons.iconAktifPlusKey.color"
      ></VueIcon>
      <VueText sizeLevel="4" weightLevel="2" color="white-100"
        >{{ this.getNameOfMonth(this.lastMonth) }} ayı AktifPlus+ ödülünü almak için
        <a href="#" @click.prevent="redirectToRosetteDetail">tıkla.</a></VueText
      >
    </div>
    <BrandButton :outlined="true" class="aktif-plus-button" @click.native="redirectToRosetteDetail">
      <VueText color="grey-40" sizeLevel="4" weightLevel="5">AKTİF+ DETAYLAR</VueText></BrandButton
    >
  </div>
</template>
<script>
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import StaticContent from '@/services/Api/staticContents';
import VueText from '@/components/shared/VueText/VueText.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesSettings from '@/router/routes/secure/RoutesSettings';
import dateUtils from '@/mixins/dateUtils';
import rosetteInfo from '@/mixins/rosetteInfo';
import LocationMixin from '@/utils/locationUtils.js';

export default {
  name: 'RosetteCard',
  mixins: [dateUtils, rosetteInfo, LocationMixin],
  async mounted() {
    await this.getRosettesList();
    await this.getRosetteRewardStatu();
  },
  created() {
    StaticContent.getStaticContent('AktifPlusTrackingBarTitle').then(res => {
      if (res.data.Data && res.data.Data.content) {
        this.AktifPlusTrackingBarTitle = res.data.Data.content;
      }
    });
    StaticContent.getStaticContent('AktifPlusTrackingBarContent').then(res => {
      if (res.data.Data && res.data.Data.content) {
        this.AktifPlusTrackingBarContent = res.data.Data.content;
      }
    });
  },
  data() {
    return {
      pinpointVisible: false,
      AktifPlusTrackingBarContent: null,
      AktifPlusTrackingBarTitle: null,
    };
  },
  components: {
    VueText,
    VueIcon,
    BrandButton,
  },
  methods: {
    redirectToRosetteDetail() {
      this.$router.push(
        `${RoutesRoot.Secure.path}/${RoutesSecure.Settings.path}/${RoutesSettings.RosettesDetail.path}`,
      );
    },
    checkEarlierPinpoint(index) {
      let res = !(
        this.rossettes.some((f, i) => {
          return f.class === 'empty' && i < index;
        }) || index === 0
      );
      this.pinpointVisible = this.pinpointVisible ? true : res;
      return res;
    },
  },
  computed: {
    constants() {
      return {
        size: COMPONENT_CONSTANTS.COMPONENT_SIZES,
        icons: {
          info: { ...ICON_VARIABLES.info, color: '#ffffff' },
          iconAktifPlusKey: { ...ICON_VARIABLES.iconAktifPlusKey, color: '#ffffff' },
        },
      };
    },
    rossettes() {
      const { totalRosetteCount, successRosetteCount, unsuccessfulRosetteCount } = this.getRosettes;
      let res = [];
      for (let index = 0; index < totalRosetteCount; index++) {
        res.push(
          index < successRosetteCount
            ? {
                class: 'star',
                img: require('@/assets/images/rossette-star.svg'),
              }
            : index < unsuccessfulRosetteCount + successRosetteCount
            ? {
                class: 'failed',
                img: require('@/assets/images/rossette-failed.svg'),
              }
            : {
                class: 'empty',
                img: require('@/assets/images/rossette-empty.svg'),
              },
        );
      }
      return res;
    },
    lastMonth() {
      let date = new Date();
      date.setDate(0);
      return date;
    },
  },
};
</script>
<style lang="scss" scoped>
.card-container {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  box-shadow: 0px 4px 10px 0px rgba(223, 231, 244, 0.13);
  background: linear-gradient(222.39deg, #9a22a5 22.53%, #9c51d7 79.53%);
  position: relative;
  z-index: 0;
  transition: height 0.5s ease-out;
  overflow: hidden;
  .progress-container {
    width: 100%;
    display: flex;
    margin-top: 15px;
    > div {
      text-align: center;
      width: 20%;
      position: relative;
      img {
        width: 45px;
      }
    }
    &-pinpoint {
      margin-top: 25px !important;
    }
    .empty {
      &:not(:first-child):before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: 35%;
        width: 100%;
        height: 6px;
        z-index: -1;
        background-image: url('~@/assets/images/aktif-plus-dot.svg');
        background-repeat: repeat-x;
      }
      &:not(:first-child):nth-child(2n + 1):before {
        left: -50%;
      }
      &:not(:first-child):nth-child(2n):before {
        left: -60%;
      }
      #pinpoint {
        position: absolute;
        width: 19px;
        height: 50%;
        top: -15px;
        left: -9.5px;
      }
    }
    .star {
      &:not(:first-child):before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: 35%;
        width: 100%;
        height: 6px;
        z-index: -1;
        left: -50%;
        background: linear-gradient(180deg, #ffe30b -7.32%, #ffa627 116.6%),
          linear-gradient(180deg, #9aff96 -7.32%, #1aa414 98.53%),
          linear-gradient(180deg, #ffd809 -7.32%, #ff8a00 98.53%);
      }
    }
    .failed {
      &:not(:first-child):before {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        top: 35%;
        width: 100%;
        height: 6px;
        z-index: -1;
        left: -50%;
        background: rgba(252, 112, 112, 1);
      }
    }
  }
  .info-text {
    display: flex;
    margin-top: 15px;
    svg {
      height: 16px;
      width: 16px;
      flex: none;
      margin-right: 5px;
    }
  }
  .prev-month-reward {
    display: flex;
    margin-top: 15px;
    align-items: center;
    a {
      color: white;
    }
    svg {
      flex: none;
      margin-right: 5px;
    }
  }
  .aktif-plus-button {
    align-self: center;
    width: 200px !important;
    border-radius: 22px;
    margin-top: 20px;
    padding: 14px 24px;
  }
}
</style>
