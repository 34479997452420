<template>
  <div>
    <div
      v-if="showBgOverlay"
      class="bg-overlay"
      @click="closePopup('BGClose', popupList[currentPopupIndex])"
    />
    <div>
      <div v-for="popup in popupList" :key="popup.id">
        <div
          :class="{ 'popup-container': true }"
          v-if="popupVisible === popup.id"
          @click.stop="closePopup('BGClose', popup)"
        >
          <div class="popup-image" @click="preventBGClose">
            <button class="close-button" @click.stop="closePopup('XButtonClose', popup)">
              X
            </button>
            <a
              v-if="popup.imgLink"
              :href="popup.imgLink"
              @click.stop="closePopup('ImageClick', popup, popup.imgLink)"
            >
              <img id="mainImage" :src="popup.mainImage" />
            </a>
            <img v-else id="mainImage" :src="popup.mainImage" />
            <div
              v-html="popup.subImageDescription"
              ref="subImageDescription"
              id="subImageDescriptionRef"
            />
            <div v-html="popup.description" />
            <a
              v-for="button in popup.buttons"
              :key="button.id"
              :href="button.link"
              v-html="button.description"
              @click.stop="closePopup('ButtonClick', popup, button.link)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicPopups from '../../../../services/Api/dynamicPopups';

const POPUP_TYPES = {
  MODAL: 1,
  DIALOG: 2,
  BOTTOM_SHEET: 3,
};

const ACTION_TYPES = {
  X_BUTTON_CLOSE: '1',
  BG_CLOSE: '2',
  BUTTON_CLICK: '3',
};

export default {
  data() {
    return {
      popupVisible: null,
      currentPopupIndex: 0,
      popupList: [],
      popupType: '',
    };
  },
  watch: {
    popupType: {
      immediate: true,
      handler(value) {
        document.body.style.overflow = value == 1 || value == 2 ? 'hidden' : 'auto';
      },
    },
    $route() {
      if (this.$route.fullPath == '/secure/feed') {
        this.fetchPopups();
      }
    },
  },
  computed: {
    showBgOverlay() {
      return (
        this.popupVisible !== null &&
        (this.popupType === POPUP_TYPES.MODAL || this.popupType === POPUP_TYPES.DIALOG)
      );
    },
  },
  methods: {
    async fetchPopups() {
      if (this.$route.fullPath != '/secure/feed') return;

      const response = await DynamicPopups.getAllDynamicPopup();

      if (!response.data.Data) return;

      this.popupList = response.data.Data.popups;
      if (this.popupList.length > 0) {
        this.openPopup(0);
      }
    },

    async viewPopups(request) {
      await DynamicPopups.viewlogDynamicPopup(request);
    },

    async openPopup(popupIndex) {
      this.popupVisible = this.popupList[popupIndex].id;

      await this.$nextTick();
      const imgElement = document.getElementById('subImgSrc');

      if (imgElement) {
        imgElement.src = this.popupList[popupIndex].subImage;
      }

      this.popupType = this.popupList[popupIndex].typeId;

      if (this.popupType === 3) {
        const popupContainer = document.querySelector('.popup-container');
        popupContainer.classList.add('popup-type-3');
      }
    },

    closePopup(type, popup, popupLink) {
      if (type === 'BGClose' && this.popupType === POPUP_TYPES.BOTTOM_SHEET) return;

      const popupContainer = document.querySelector('.popup-container');

      if (popupContainer) {
        let actionType;
        if (type === 'XButtonClose') {
          actionType = ACTION_TYPES.X_BUTTON_CLOSE;
        } else if (type === 'BGClose') {
          actionType = ACTION_TYPES.BG_CLOSE;
        } else {
          actionType = ACTION_TYPES.BUTTON_CLICK;
        }

        const request = {
          actionValue: popupLink ?? '',
          actionType,
          dynamicPopupId: popup.id,
        };
        this.popupVisible = null;

        this.viewPopups(request);

        this.currentPopupIndex++;

        if (this.currentPopupIndex < this.popupList.length && !popupLink) {
          this.openPopup(this.currentPopupIndex);
        }

        if (this.popupType === 3) {
          const popupContainer = document.querySelector('.popup-container');
          if (popupContainer) {
            popupContainer.classList.remove('popup-type-3');
          }
        }
      }
    },

    preventBGClose(event) {
      event.stopPropagation();
    },
  },
  created() {
    this.fetchPopups();
  },
};
</script>

<style scoped>
.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 998;
}

.popup-container {
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  z-index: 999;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.close-button {
  background-color: transparent;
  position: absolute;
  border-radius: 50%;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  border: none;
  display: flex;
  top: 1px;
  right: 1px;
  width: 60px;
  height: 60px;
}

.popup-image {
  position: relative;
}

.popup-image button {
  color: white;
}

.popup-container.popup-type-3 {
  margin-top: 25%;
}
</style>
